import { EventService } from "./../../../services/event.service";
import {
    EVENT_SERVICE,
    TRACKS_SERVICE
} from "./../../../providers/service.providers";
import { Component, OnInit, Input, Inject } from "@angular/core";
import { SessionTrack } from "../../../models/SessionTrack";
import { Session } from "../../../models/Session";
import { TracksService } from "src/app/services/tracks.service";
import { ActivatedRoute } from '@angular/router';
import { EventQueryParameterNames } from '../event.component';
import { LocalizableError } from 'src/app/models/LocalizableError';

@Component({
    selector: "app-sessiontracks",
    templateUrl: "./sessiontracks.component.html",
    styleUrls: ["./sessiontracks.component.css"]
})
export class SessiontracksComponent implements OnInit {
    public cntActiveDataRetrievals = 0;
    public error: LocalizableError;

    readableEventId: string;
    sessionTracks: SessionTrack[];
    selectedSessionTrackId: string;
    sessionsInSelectedTrack: Session[];

    constructor(
        private route: ActivatedRoute,
        @Inject(EVENT_SERVICE) private eventService: EventService,
        @Inject(TRACKS_SERVICE) private tracksService: TracksService
    ) {}

    ngOnInit() {
        this.route.queryParamMap.subscribe(paramMap => {
            this.readableEventId = paramMap.get(EventQueryParameterNames.ReadableEventId);
            this.loadSessionTracks();
        });
    }

    private loadSessionTracks(): void {
        this.cntActiveDataRetrievals += 1;
        this.eventService.getSessionTracks(this.readableEventId).subscribe(
            tracks => {
                this.sessionTracks = tracks;
                
                if (tracks.length > 0) {
                    this.selectedSessionTrackId = tracks[0].id;
                    this.loadSessionsInTrack(this.selectedSessionTrackId);
                }

                this.cntActiveDataRetrievals -= 1;
            },
            (error: LocalizableError) => this.handleErrorResponse(error)
        );
    }

    private loadSessionsInTrack(trackId: string): void {
        this.cntActiveDataRetrievals += 1;
        this.tracksService.getSessions(trackId).subscribe(
            sessions => {
                this.sessionsInSelectedTrack = sessions;
                this.cntActiveDataRetrievals -= 1;
            },
            (error: LocalizableError) => this.handleErrorResponse(error)
        );
    }

    private handleErrorResponse(error: LocalizableError) {
        this.cntActiveDataRetrievals -= 1;
        this.error = error;
    }
}
