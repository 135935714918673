import { EVENT_SERVICE } from "./../../../providers/service.providers";
import { EventService } from "../../../services/event.service";
import {
    Component,
    OnInit,
    Input,
    ViewChild,
    Inject,
    ElementRef
} from "@angular/core";
import { Speaker } from "../../../models/Speaker";
import { ImageHelper } from "../../../helpers/ImageHelper";
import { ScrollHelper } from "src/app/helpers/ScrollHelper";
import { ActivatedRoute } from "@angular/router";
import { EventQueryParameterNames } from '../event.component';
import { LocalizableError } from 'src/app/models/LocalizableError';

@Component({
    selector: "app-speakers",
    templateUrl: "./speakers.component.html",
    styleUrls: ["./speakers.component.css"]
})
export class SpeakersComponent implements OnInit {
    @Input()
    readableEventId: string;

    @ViewChild("speakerDetails", { static: true})
    speakerDetailsContainer: ElementRef;

    public speakers: Speaker[];
    public speaker: Speaker;
    public isLoading = false;
    public error: LocalizableError;

    defaultImageUrl = "default_contact_image.png";

    constructor(
        private route: ActivatedRoute,
        private imageHelper: ImageHelper,
        @Inject(EVENT_SERVICE) private eventService: EventService
    ) {}

    ngOnInit() {
        this.route.queryParamMap.subscribe(paramMap => {
            this.readableEventId = paramMap.get(EventQueryParameterNames.ReadableEventId);
            this.loadSpeakers();
        });
    }

    public selectSpeaker(speaker: Speaker): void {
        this.speaker = speaker;

        ScrollHelper.scrollToElement(
            this.speakerDetailsContainer.nativeElement
        );
    }

    public selectSpeakerById(speakerId: string) {
        if (speakerId && this.speakers) {
            const speakerToSelect = this.speakers.find(
                speaker => speaker.id === speakerId
            );

            if (speakerToSelect != null) {
                this.selectSpeaker(speakerToSelect);
            }
        }
    }

    private loadSpeakers() {
        this.isLoading = true;
        this.eventService.getSpeakers(this.readableEventId).subscribe(
            speakers => {
                this.speakers = speakers;

                this.route.queryParamMap.subscribe(paramMap => {
                    const selectedSpeakerId = paramMap.get(EventQueryParameterNames.SpeakerId);
                    this.selectSpeakerById(selectedSpeakerId);
                });

                this.isLoading = false;
            },
            (error: LocalizableError) => this.handleErrorResponse(error)
        );
    }

    private handleErrorResponse(error: LocalizableError) {
        this.isLoading = false;
        this.error = error;
    }
}
